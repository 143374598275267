@keyframes pulse {
  0%,
  100% {
    r: 4;
    opacity: 0.1;
  }
  50% {
    r: 6;
    opacity: 0.3;
  }
}

.animate-pulses {
  animation: pulse 1.5s ease-in-out infinite alternate forwards;
}
