.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  font-family: "Mulish", sans-serif;
  border: 1px solid #eaecf0;
  border-radius: 12px;
  width: 100%;
}

.react-datepicker-popper {
  z-index: 50;
}

.react-datepicker__header {
  background-color: white;
  border: none;
}

.react-datepicker__month-text--keyboard-selected,
.react-datepicker__month-text--keyboard-selected:hover {
  background-color: #2970ff;
  color: white;
}

.react-datepicker__month .react-datepicker__month-text {
  width: 50px;
  border-radius: 32px;
  margin: 6px;
}
