.react-multi-email {
  border: 1px solid #d1d5db;
}

.react-multi-email.focused {
  border-color: #d1d5db;
}

.react-multi-email.empty > span[data-placeholder] {
  color: #9ca3af;
}

.react-multi-email [data-tag] {
  background-color: #f2f4f7;
  border-radius: 4px;
  color: #667085;
}

.react-multi-email [data-tag] [data-tag-handle] {
  margin-left: 8px;
}

@media (max-width: 768px) {
  .react-multi-email {
    font-size: 16px !important;
  }
}
