.wrapper-class {
  height: 100%;
  display: grid;
  grid-template-rows: auto minmax(0, 1fr);
  overflow: hidden;
}

.wrapper-disabled-class {
  border: 1px solid #f3f5f7;
  border-radius: 12px;
  height: 100%;
  overflow: hidden;
}

.editor-class {
  padding: 7px 3px 7px 12px;
  height: 102%;
  overflow-y: hidden;
  cursor: text;
}

.disabled {
  background-color: #f3f5f7;
}

.DraftEditor-editorContainer {
  height: unset;
}

.DraftEditor-root {
  height: 100%;
  overflow-y: auto;
  padding-right: 8px;
  padding-bottom: 20px;
}
.DraftEditor-root::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.DraftEditor-root::-webkit-scrollbar-track {
  background: transparent;
}
.DraftEditor-root::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}
.DraftEditor-root::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}

.rdw-editor-toolbar {
  border: none;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 12px 12px 0 0;
  padding: 8px;
  margin: 0;
}

.rdw-dropdown-wrapper {
  border: none;
  border-radius: 8px;
  font-size: 14px;
}
.rdw-dropdown-wrapper:hover {
  box-shadow: none;
  background-color: #f3f4f6;
}

.rdw-dropdown-optionwrapper:hover {
  box-shadow: none;
}
.rdw-dropdown-optionwrapper::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.rdw-dropdown-optionwrapper::-webkit-scrollbar-track {
  background: transparent;
}
.rdw-dropdown-optionwrapper::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}
.rdw-dropdown-optionwrapper::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}

.rdw-option-wrapper {
  border: none;
  border-radius: 8px;
}
.rdw-option-wrapper:hover {
  box-shadow: none;
  background-color: #f3f4f6;
}
.rdw-option-active {
  box-shadow: none;
  background-color: #e5e7eb;
}

.public-DraftStyleDefault-block {
  margin: 0.2em 0;
}
.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 0.2em 0;
}

.hidePlaceholder > .DraftEditor-root > .public-DraftEditorPlaceholder-root {
  display: none;
}
