.mentions--multiLine .mentions__input::-webkit-scrollbar {
  width: 6px;
}
.mentions--multiLine .mentions__input::-webkit-scrollbar-track {
  background: transparent;
}
.mentions--multiLine .mentions__input::-webkit-scrollbar-thumb {
  background: #eaecf0;
  border-radius: 10px;
}
.mentions--multiLine .mentions__input::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}

.mentions--multiLine .mentions__highlighter::-webkit-scrollbar {
  width: 6px;
}
.mentions--multiLine .mentions__highlighter::-webkit-scrollbar-track {
  background: transparent;
}
.mentions--multiLine .mentions__highlighter::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}
.mentions--multiLine .mentions__highlighter::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.mentions--multiLine .mentions__control {
  font-size: 14px;
}
.mentions--multiLine .mentions__control textarea.mentions__input {
  font-size: 14px !important;
}
.mentions--multiLine .mentions__control textarea.mentions__input:disabled {
  font-size: 12px !important;
}

@media (max-width: 767px) {
  .mentions--multiLine .mentions__control {
    font-size: 16px;
  }
  .mentions--multiLine .mentions__control textarea.mentions__input {
    font-size: 16px !important;
  }
  .mentions--multiLine .mentions__control textarea.mentions__input:disabled {
    font-size: 12px !important;
  }
}
